import React from "react"
import "./css/parceiros.scss"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import Form from "../components/form/form";

const handleSubmit = (event) => {
  event.preventDefault();
  const data = new FormData(event.target);

  //prevents spam
  if (event.target.sc.value!=='') {
    return;
  }

  fetch('https://script.google.com/macros/s/AKfycbwc0Bh6oqn5zr_6ZbNytUInZHbLJjrHQZTcVLZs14qjTYh7sjDQ/exec', {
    method: 'POST',
    body: data,
  });
}

const ContactPage = () => (
  <Layout>
  <SEO title="Parceiros" keywords={[`parceiros`, `auto-atendimento`, `pedidos delivery`, `pedidos entrega`, `pedidos retirada` ]} />
    <Header siteTitle="QueroPedir">
      <h1>Seja Nosso Parceiro</h1>
    </Header>
    <div className="container become-partner">
      <div className="row">
        <h4><strong>Vantagens</strong> para seu negócio</h4>
      </div>
      <div className="row">
        <div className="col-sm text-center"> 
          <div className="advantages-card">
            <p>Nossa taxa <strong>não</strong> incide no <strong>valor da entrega</strong> (apenas custo de operação transacional se houver).</p>
          </div>
        </div>
        <div className="col-sm text-center">
          <div className="advantages-card">
            <p>Pague somente pelo o que vender, sem <strong>mensalidade</strong> e <strong>custo de implementação</strong>. </p>
          </div>
        </div>
        <div className="col-sm text-center">
          <div className="advantages-card">
            <p>Realizamos o <strong>pré-cadastro</strong> do seus produtos.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <h4><strong>Ferramentas</strong> para seu negócio</h4>
      </div>
      <div className="row">
        <div className="col-md-3 text-md-right become-partner-title">
          <h4>Vendas</h4>
        </div>
        <div className="col-md">
          <h5>Auto-Atendimento</h5>
          <ul>
            <li><strong>Agilize a realização dos pedidos.</strong> Reduza o tempo de entrega dos pedidos na mesa e melhore a qualidade do atendimento.</li>
            <li><strong>Reduza custos de atendimento.</strong> Forneça um atendente exclusivo para receber seus pedidos através do nosso aplicativo.</li>
          </ul>
          <h5>Entrega/Retirada</h5>
          <ul>
            <li><strong>Agilize o recebimento dos pedidos.</strong> Reduza o tempo gasto no telefone e com linhas ocupadas.</li>
            <li><strong>Mantenha seu cliente sempre informado.</strong> Informe ao seu cliente quando o pedido começou a ser preparado e quando saiu para entrega.</li>
            <li><strong>Pagamento direto no aplicativo.</strong> Ofereça ao seus clientes maior comodidade e agilidade na hora de pagar.</li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 text-md-right become-partner-title">
          <h4>Vitrine</h4>
        </div>
        <div className="col-md">
          <ul>
            <li><strong>Melhor visibilidade aos seus produtos.</strong> Adicione fotos e detalhes dos produtos para impulsionar suas vendas.</li>
            <li><strong>Altere sempre que quiser sua vitrine.</strong> Quando precisar modificar seus produtos faça isso de maneira fácil e imediata.</li>
            <li><strong>Precificação ao seu controle.</strong> Gerencie preços dos seus produtos, crie ofertas, controle o valor de entrega e taxa de serviço do seu estabelecimento..</li>
            <li><strong>Tenha mais de uma vitrine.</strong> Seus produtos podendo variar de acordo com dia e hora.</li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 text-md-right become-partner-title">
          <h4>Marketing</h4>
        </div>
        <div className="col-md">
          <ul>
            <li><strong>Crie promoções com facilidade.</strong> Você pode criar promoções para determinado dia ou horário, e ainda pode gerar imagens para publicar em suas redes sociais.</li>
            <li><strong>Tenha um canal a mais de vendas com baixo custo.</strong> Seja visto pelos seus clientes e potenciais novos através do nosso aplicativo.</li>
          </ul>
        </div>
      </div>      
      <div className="partner-contact">
        <div className="row">
          <h2>Vamos conversar?</h2>
        </div>
        <div className="row text-center">
          <p className="text-center">Preencha o formulário e entraremos em contato.</p>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md">
            <Form formName="Parceiros">
              <input name="Assunto" type="hidden" value="Parceiro" required/>
              <input name="Nome" type="text" placeholder="Nome" className="form-control" required/>
              <input name="Email"  type="email" placeholder="E-mail" className="form-control" required/>
              <input name="Telefone"  type="telefone" placeholder="Telefone" className="form-control" required/>
              <input name="Nome do Estabelecimento" type="text" placeholder="Nome do Estabelcimento" className="form-control" required/>
              <select name="Estado" className="form-control" required>
                <option value="" disabled selected hidden>Estado</option>
                <option value="Alagoas">Alagoas</option>
                <option value="Amapá">Amapá</option>
                <option value="Amazonas">Amazonas</option>
                <option value="Bahia">Bahia</option>
                <option value="Ceará">Ceará</option>
                <option value="Distrito Federal">Distrito Federal</option>
                <option value="Espírito Sant">Espírito Santo</option>
                <option value="Goiás">Goiás</option>
                <option value="Maranhão">Maranhão</option>
                <option value="Mato Grosso">Mato Grosso</option>
                <option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
                <option value="Minas Gerais">Minas Gerais</option>
                <option value="Pará">Pará</option>
                <option value="Paraíba">Paraíba</option>
                <option value="Paraná">Paraná</option>
                <option value="Pernambuco">Pernambuco</option>
                <option value="Piauí">Piauí</option>
                <option value="Rio de Janeiro">Rio de Janeiro</option>
                <option value="Rio Grande do Norte">Rio Grande do Norte</option>
                <option value="Rio Grande do Sul">Rio Grande do Sul</option>
                <option value="Rondônia">Rondônia</option>
                <option value="Roraima">Roraima</option>
                <option value="Santa Catarina">Santa Catarina</option>
                <option value="São Paulo">São Paulo</option>
                <option value="Sergipe">Sergipe</option>
                <option value="Tocantins">Tocantins</option>
              </select>
              <input name="Cidade" type="text" placeholder="Cidade" className="form-control" required/>
              <select name="Tipo de Estabelecimento" className="form-control" required>
                <option value="" disabled selected hidden>Tipo de estabelecimento</option>
                <option value="Bar">Bar</option>
                <option value="Restaurante">Restaurante</option>
                <option value="Restaurante Japonês">Restaurante Japonês</option>
                <option value="Rede de Franquias">Rede de Franquias</option>
                <option value="Pub / Balada">Pub/ Balada</option>
                <option value="Cafeteria">Cafeteria</option>
                <option value="Doceria">Doceria</option>
                <option value="Pizzaria">Pizzaria</option>
                <option value="Padaria">Padaria</option>
                <option value="Casa de Açaí / Sorveteria">Casa de Açaí / Sorveteria</option>
                <option value="Pastelaria">Pastelaria</option>
                <option value="PetShop">PetShop</option>
                <option value="Água e Gás">Água e Gás</option>
                <option value="Hortifruti">Supermercado</option>
                <option value="Hortifruti">Hortifruti</option>                
                <option value="Farmácia">Farmácia</option>
                <option value="Adega">Adega</option>
                <option value="Não Possuo Estabelecimento">Não Possuo Estabelecimento</option>
                <option value="Outros">Outros</option>
              </select>
              <div className="partner-contact-interest">
                <p>Estou interessado em:</p>
                <label><input type="radio" name="Interesse" value="Delivery" /> Pedidos Entrega/Retirada</label>
                <label><input type="radio" name="Interesse" value="Pedido na Mesa" /> Auto-Atendimento</label>
                <label><input type="radio" name="Interesse" value="Ambos" /> Ambos</label>
              </div>
              <textarea name="Mensagem"  type="text" placeholder="Mensagem opcional..." className="form-control" rows="5"/>
            </Form>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
